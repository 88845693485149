/* eslint-disable indent */
import { useCallback, useMemo } from "react";

import shallow from "zustand/shallow";
import { useRouter } from "next/router";

import { useUserDataStore } from "@/store/UserDataStore";
import { funnelTagsSorter } from "@/helpers/funnelTagsSorter";
import { shuffle } from "@/helpers/helperFunctions";

import {
	calculateFeedingGuide,
	calculatePackWeights,
	calculateRecipe,
	getPredictedAdultWeight,
} from "../API";
import {
	getTagConditions,
	identify,
	prioritiseTagsToDisplay,
	track,
} from "../helpers";
import {
	useAccountDataStore,
	useFunnelStore,
} from "../store";
import {
	Fields,
	FunnelData,
	Pet,
	RecipeOverview,
	UnsuitableRecipeOverview,
} from "../types";

export const usePetFactory = () => {
	const [
data,
firstName,
email,
marketingOptIn,
removePet
] = useFunnelStore(
		(state) => [
			state.data,
			state.firstName,
			state.email,
			state.marketingOptIn,
			state.removePet,
		]
	);

	const [user, updateUser] = useUserDataStore(
		(state) => [state.user, state.updateUser],
		shallow
	);

	const [accountUser] = useAccountDataStore((state) => [state.user]);

	const { query } = useRouter();

	const activePets = useMemo(() => {
		if (!user.pets) {
			return [];
		}

		const { iteration } = query;

		return Object.values(user.pets).reduce((list, pet) => {
			if (`${pet.iteration}` === `${iteration}`) {
				return [...list, pet];
			}

			return list;
		}, [] as Pet[]);
	}, [query, user.pets]);

	const isFunnelDataComplete = useCallback(() => {
		if (!user.sales_person_id && !user.email && !email) {
			return false;
		}

		return data.every((pet) => {
			return Object.keys(pet)
				.filter((key) => {
					if (user.sales_person_id) {
						return !["goal"].includes(key);
					}
					if (user.email || accountUser?.email) {
						return !["email", "firstName"].includes(key);
					}

					return true;
				})
				.every((key) => {
					if (key === "months" && pet["years"]) {
						return true;
					}
					if (key === "years" && pet["months"]) {
						return true;
					}

					if (
						key === "neutered" &&
						(!pet["years"] || parseInt(`${pet["years"]}`) === 0) &&
						pet["months"] &&
						pet["months"] <= 4
					) {
						return true;
					}

					if (
						[
							"estimatedWeight",
							"oldPuppyAge",
							"adultAge",
							"marketingOptIn",
							"improveHealth",
							"goal",
						].includes(key)
					) {
						// keys to skip
						return true;
					}
					// If no health improvements, exclude them from the check
					if (
						!pet["improveHealth"] &&
						[
							"coat",
							"behavioural",
							"goal",
							"mobility",
							"oral",
							"shape",
							"stools",
							"digestion",
						].includes(key)
					) {
						return true;
					}

					if (key === "breedCount") {
						return true;
					}

					// Assumes any values that are boolean are ok to proceed with.
					if (typeof pet[key as keyof typeof pet] === "boolean") {
						return true;
					}

					const result = !!pet[key as keyof typeof pet];

					if (!result) {
						console.log("Missing or incorrect data - "+key+" - "+pet[key as keyof typeof pet])
						const stringdata = data.map((pet) =>
							Object.keys(pet)
								.map((key) => `${key}: ${pet[key as keyof typeof pet]}`)
								.join(", ")
						);
						stringdata.forEach((petInfo) => console.log(petInfo));

						track("Funnel missing data", {
							step: "Is funnel data complete",
							email: email,
							petId: pet[Fields.PETID],
							data,
							rawData: stringdata.join(" ---PET--- "),
						});

						console.log(`Pets in funnel data: ${data.length}`);
						console.error("Funnel errored - delete the pet");
						pet.petId && removePet(pet.petId);
					}

					// Any values that are falsey and not a boolean will fail here
					return result;
				});
		});
	}, [
		accountUser?.email,
		data,
		email,
		removePet,
		user.email,
		user.sales_person_id,
	]);

	const getTagsAndBenefits = useCallback(
		(suitableRecipes: RecipeOverview[], allFunnelDataSafe: FunnelData) => {
			const showBenefitsAndTags = suitableRecipes.length > 0;

			const [benefits, tags] = showBenefitsAndTags
				? suitableRecipes.reduce<
						[
							{ benefits: string[]; handle: string }[],
							{ tags: string[]; handle: string }[]
						]
				  >(
						([benefitsList, tagsList], { filteredBenefits, tags, handle }) => {
							benefitsList.push({ benefits: filteredBenefits || [],
handle });
							tagsList.push({ tags,
handle });

							return [benefitsList, tagsList];
						},
						[[], []]
				  )
				: [[], []];

			const conditionsToDisplayTags =
				allFunnelDataSafe.lifestage && allFunnelDataSafe.fussy
					? getTagConditions(
							allFunnelDataSafe.lifestage,
							allFunnelDataSafe.fussy
					  )
					: {};

			const tagsPrioritised = prioritiseTagsToDisplay(
				tags,
				conditionsToDisplayTags
			);

			return {
				benefits,
				tags: tagsPrioritised,
			};
		},
		[]
	);

	const getAllPackWeights = useCallback(
		async (
			{
				transformedAge,
				breed,
				weight,
				shape,
				activity,
				neutered,
				treats,
				lifestage,
				adultAge,
			}: Required<FunnelData>,
			scoopAmount: number
		) => {
			const currentPackWeights = calculatePackWeights(
				"sub",
				scoopAmount,
				"new"
			);

			if (lifestage === "adult" || lifestage === "senior") {
				return {
					currentPackWeights,
					adultPackWeights: null,
				};
			}

			const { predicted_weight: predictedWeightWhenAdult } =
				await getPredictedAdultWeight({
					age: transformedAge,
					breed: breed,
					weight: weight,
				});

			const adultFeedingGuide = await calculateFeedingGuide(
				adultAge,
				predictedWeightWhenAdult,
				activity,
				shape,
				breed,
				"dog",
				neutered === "yes"
? true
: false,
				treats,
				"adult"
			);

			const adultPackWeights = calculatePackWeights(
				"sub",
				adultFeedingGuide.scoop_amount,
				"new"
			);

			return {
				currentPackWeights,
				adultPackWeights,
				adultFeedingGuide,
			};
		},
		[]
	);

	const createPetFromStore = useCallback(async () => {
		if (!isFunnelDataComplete()) {
			return null;
		}

		return Promise.all(
			data.map(async (pet, index) => {
				const allFunnelDataSafe = pet as Required<FunnelData>;
				// GET FEEDING GUIDE
				const feedingGuide = await calculateFeedingGuide(
					allFunnelDataSafe[Fields.TRANSFORMEDAGE],
					allFunnelDataSafe[Fields.WEIGHT],
					allFunnelDataSafe[Fields.ACTIVITY],
					allFunnelDataSafe[Fields.SHAPE],
					allFunnelDataSafe[Fields.BREED],
					"dog",
					allFunnelDataSafe[Fields.NEUTERED] === "yes"
						? true
						: false,
					allFunnelDataSafe[Fields.TREATS],
					allFunnelDataSafe[Fields.LIFESTAGE]
				);

				const healthIssuesConcat = [
					...allFunnelDataSafe.healthIssues,
					...(allFunnelDataSafe.digestion?.includes("anal gland issues")
						? ["anal gland issues"]
						: []),
					...(allFunnelDataSafe.coat?.filter(
						(issue) => issue !== "tear stains" && issue !== "none"
					)?.length > 0
						? ["sensitive skin"]
						: []),
					...(allFunnelDataSafe.coat?.includes("tear stains")
						? ["tear stains"]
						: []),
					...(allFunnelDataSafe.digestion?.includes("a sensitive stomach")
						? ["a sensitive stomach"]
						: []),
				];

				const healthIssuesFiltered =
					healthIssuesConcat.length > 0
						? healthIssuesConcat.filter((issue) => issue !== "none")
						: healthIssuesConcat;

				const healthIssuesDuplicatesRemoved = [...new Set(healthIssuesFiltered),];

				// GET RECIPE
				const recipes = await calculateRecipe(
					allFunnelDataSafe[Fields.TRANSFORMEDAGE],
					allFunnelDataSafe[Fields.ALLERGIES],
					healthIssuesDuplicatesRemoved,
					allFunnelDataSafe[Fields.FUSSY],
					allFunnelDataSafe[Fields.WEIGHT],
					"product,treat,supplement,christmas,chew,bundle,accessory",
					allFunnelDataSafe[Fields.BREED] || undefined,
					allFunnelDataSafe[Fields.SHAPE] || undefined
				);

				const {
					currentPackWeights: packWeights,
					adultPackWeights,
					adultFeedingGuide,
				} = await getAllPackWeights(
					allFunnelDataSafe,
					feedingGuide.scoop_amount
				);

				// GET THE PRODUCT

				const productToDefaultTo = recipes.filter(
					(recipe) => recipe.type === "product"
				)?.[0];

				const subProduct = productToDefaultTo?.product?.variants.filter(
					(variant) => {
						return parseInt(variant.title) === packWeights[0];
					}
				)[0];

				const adultSubProduct = adultPackWeights
					? productToDefaultTo?.product?.variants.filter((variant) => {
							return parseInt(variant.title) === adultPackWeights[0];
					  })[0]
					: null;

				// CHECK FOR TESTS & ASSIGN PRICES
				let priceTest;

				const planPrice = subProduct
? subProduct.price / 100
: 0;

				const basePrice = adultSubProduct
					? adultSubProduct?.price / 100
					: planPrice;

				// PRICE TEST
				if (subProduct?.price_tests.length > 0) {
					// NO PRICE TESTS CURRENTLY
				}

				// clone the user object
				const newUser = {
					...user,
					email: email,
					firstName: firstName,
					contact_via_email: marketingOptIn,
				};

				// @ts-expect TODO not sure what this error is because of, the types are the same...
				updateUser(newUser);

				const recipeProducts = recipes.filter(
					(recipe) =>
						recipe.type === "product" &&
						!recipe.discontinued &&
						recipe.show_in_funnel
				);

				const suitableRecipes =
					(allFunnelDataSafe[Fields.TRANSFORMEDAGE] &&
					allFunnelDataSafe[Fields.ACTIVITY] &&
					healthIssuesDuplicatesRemoved &&
					allFunnelDataSafe[Fields.GOAL]
						? funnelTagsSorter(
								recipeProducts,
								allFunnelDataSafe[Fields.TRANSFORMEDAGE],
								allFunnelDataSafe[Fields.ACTIVITY],
								healthIssuesDuplicatesRemoved,
								allFunnelDataSafe[Fields.GOAL]
						  )
						: recipeProducts
					)?.filter(({ suitable }) => suitable) || [];

				// GET JUST THE HANDLES OF THE RECIPES IN AN ARRAY
				const handles = suitableRecipes?.map(({ handle }) => handle);

				const { tags, benefits } = getTagsAndBenefits(
					suitableRecipes,
					allFunnelDataSafe
				);
				const unsuitableRecipes: UnsuitableRecipeOverview[] = recipeProducts
					.filter((recipe: RecipeOverview) => {
						return !recipe.suitable;
					})
					.map((recipe) => {
						return {
							handle: recipe.handle,
							conditions: recipe.pet_size_inappropriate,
							breeds: recipe.breed_inappropriate,
							ailments: recipe.health_warnings,
							allergens: recipe.allergens,
						};
					});
				// SHUFFLE THE HANDLES ARRAY
				let shuffledRecipes = handles.sort(() => 0.5 - Math.random());

				const shuffledUnsuitableRecipes = unsuitableRecipes.sort(
					() => 0.5 - Math.random()
				);

				// Default preselect salmon and turkey if available
				let hasTurkey = false;
				let hasSalmon = false;
				let hasDuck = false;

				shuffledRecipes = shuffledRecipes.filter((item) => {
					switch (item) {
						case "tempting-turkey":
							hasTurkey = true;

							return false;

						case "super-salmon":
							hasSalmon = true;

							return false;

						case "delicious-duck":
							hasDuck = true;

							return false;

						default:
							return true;
					}
				});

				if (hasSalmon) {
					shuffledRecipes.unshift("super-salmon");
				}
				if (hasTurkey) {
					shuffledRecipes.unshift("tempting-turkey");
				}
				if (hasDuck) {
					shuffledRecipes.push("delicious-duck");
				}

				// GET THE PRESELECTED RECIPES
				// IF THEYVE ALREADY FILLED IN THE FORM PREVIOUSLY, TRY AND MATCH THE NEW RANDOMS WITH THE OLD
				const simultaneousRecipes = Math.min(feedingGuide.scoop_amount, 2);

				const selectedRecipes =
					activePets
						.find(
							({ name }) =>
								name?.toLowerCase().trim() ===
								pet[Fields.NAME]?.toLowerCase().trim()
						)
						?.selectedRecipes?.filter((selected) =>
							handles.includes(selected)
						) || shuffledRecipes.slice(0, simultaneousRecipes);

				if (
					activePets.find(
						({ name }) =>
							name?.toLowerCase().trim() ===
							pet[Fields.NAME]?.toLowerCase().trim()
					)?.selectedRecipes
				) {
					// IF THATS NOT MATCHED 2, BACKFILL THE REST WITH NEW RECIPES IF WE CAN
					for (const shuffled of shuffledRecipes) {
						if (
							selectedRecipes.length < simultaneousRecipes &&
							!selectedRecipes.includes(shuffled)
						) {
							selectedRecipes.push(shuffled);
						}
					}

					// RE-ORDER SHUFFLED RECIPES SO THAT SELECTED RECIPES ARE FIRST
					shuffledRecipes = shuffledRecipes.filter(
						(shuffled) => !selectedRecipes.includes(shuffled)
					);
					shuffledRecipes = selectedRecipes.concat(shuffledRecipes);
				}

				let chosenRecipe = "";
				if (recipes[0]) {
					chosenRecipe = recipes.filter((recipe) => {
						return recipe.suitable && !recipe.discontinued;
					})[0]?.handle;
				}

				let filteredTreats = recipes
					.filter((recipe) => {
						return (
							recipe.suitable &&
							(recipe.type === "treat" ||
								recipe.type === "bundle" ||
								recipe.type === "supplement" ||
								recipe.type === "chew" ||
								recipe.type === "christmas" ||
								recipe.type === "accessory") &&
							!recipe.discontinued &&
							recipe.show_in_funnel
						);
					})
					.sort(({ order: order1 }, { order: order2 }) => order1 - order2)
					.map((thisTreat) => thisTreat.handle);

				const trainingTreats = shuffle(
					filteredTreats.filter((treat) => treat.includes("training"))
				);
				const everydayTreats = shuffle(
					filteredTreats.filter(
						(treat) =>
							treat === "drool-worthy-duck-venison-delights" ||
							treat === "decadent-lamb-chunks" ||
							treat === "irresistible-beef-bites" ||
							treat === "flavoursome-fish-pieces" ||
							treat === "charming-duck-venison-strawberry-hearts"
					)
				);
				
				const dentalOrYak = shuffle(
					filteredTreats.filter(
						(treat) =>
							treat.includes("deep-clean-dental-sticks") ||
							(allFunnelDataSafe.weight >= 5 && treat.includes("milk-chew"))
					)
				);
				const finalExtra = shuffle(
					filteredTreats.filter(
						(treat) =>
							treat === "salmon-oil" ||
							treat === "poo-bags" ||
							treat === "calming-daily-bites" ||
							treat === "probiotic-daily-bites" ||
							treat === "daily-joint-supplement"
					)
				);

				const remainingTreats = filteredTreats.filter(
					(treat) =>
						treat !== trainingTreats?.[0] &&
						treat !== everydayTreats?.[0] &&
						treat !== dentalOrYak?.[0] &&
						treat !== finalExtra?.[0] &&
						!(allFunnelDataSafe.weight < 5 && treat.includes("milk-chew"))
				);

				filteredTreats = [
					trainingTreats?.[0],
					everydayTreats?.[0],
					dentalOrYak?.[0],
					finalExtra?.[0],
					...remainingTreats,
				].filter((treat) => !!treat);

				let maxTreats = 4;

				if (user.sales_person_id) {
					maxTreats = filteredTreats.length;
				}

				if (
					activePets.find(
						({ name }) =>
							name?.toLowerCase().trim() ===
							pet[Fields.NAME]?.toLowerCase().trim()
					)?.availableTreats
				) {
					// IF THEYVE ALREADY FILLED IN THE FORM PREVIOUSLY, TRY AND MATCH THE NEW RANDOMS WITH THE OLD
					const availableTreats = activePets
						.find(
							({ name }) =>
								name?.toLowerCase().trim() ===
								pet[Fields.NAME]?.toLowerCase().trim()
						)
						?.availableTreats?.filter(
							(selected) => !!filteredTreats.includes(selected)
						);

					// IF THATS NOT MATCHED 2, BACKFILL THE REST WITH NEW RECIPES IF WE CAN
					for (const filtered of filteredTreats) {
						if (
							availableTreats &&
							availableTreats.length < maxTreats &&
							!availableTreats.includes(filtered)
						) {
							availableTreats.push(filtered);
						}
					}

					filteredTreats = availableTreats || [];
				} else {
					// TAKE TOP TREATS
					filteredTreats = filteredTreats.slice(0, maxTreats);
				}

				const transformedPetDetails: Pet = {
					hasSelectedPlan: false,
					index: index,
					iteration: 0,
					// Pet info
					gender: allFunnelDataSafe[Fields.GENDER] as "male" | "female",
					pronouns: allFunnelDataSafe[Fields.PRONOUNS],
					breed: allFunnelDataSafe[Fields.BREED],
					age: allFunnelDataSafe[Fields.TRANSFORMEDAGE],
					name: allFunnelDataSafe[Fields.NAME],
					petId: allFunnelDataSafe[Fields.PETID],
					allergies:
						allFunnelDataSafe[Fields.ALLERGIES][0] === "none"
							? []
							: allFunnelDataSafe[Fields.ALLERGIES],
					healthIssues:
						allFunnelDataSafe[Fields.HEALTHISSUES][0] === "none"
							? []
							: allFunnelDataSafe[Fields.HEALTHISSUES],
					healthIssuesLong: healthIssuesDuplicatesRemoved,
					weight: allFunnelDataSafe[Fields.WEIGHT],
					activity: allFunnelDataSafe[Fields.ACTIVITY],
					treatsFed: allFunnelDataSafe[Fields.TREATS],
					shape: !allFunnelDataSafe[Fields.IMPROVEHEALTH]
						? "just right"
						: allFunnelDataSafe[Fields.SHAPE],
					fussy: allFunnelDataSafe[Fields.FUSSY],
					goal: allFunnelDataSafe[Fields.GOAL],
					diet: allFunnelDataSafe[Fields.DIET],
					neutered: allFunnelDataSafe[Fields.NEUTERED] === "yes"
? true
: false,
					recipe: chosenRecipe,
					lifestage: allFunnelDataSafe[Fields.LIFESTAGE],
					adultAge: allFunnelDataSafe[Fields.ADULTAGE],
					oldPuppyAge: allFunnelDataSafe[Fields.OLDPUPPYAGE],
					adultWeight: allFunnelDataSafe[Fields.ADULTWEIGHT],
					workingDog: allFunnelDataSafe[Fields.WORKINGDOG],
					digestion: allFunnelDataSafe[Fields.DIGESTION],
					behavioural: allFunnelDataSafe[Fields.BEHAVIOURAL],
					stools: allFunnelDataSafe[Fields.STOOLS],
					improveHealth: allFunnelDataSafe[Fields.IMPROVEHEALTH],
					mobility: allFunnelDataSafe[Fields.MOBILITY],
					oral: allFunnelDataSafe[Fields.ORAL],
					coat: allFunnelDataSafe[Fields.COAT],
					breedCount: allFunnelDataSafe[Fields.BREEDCOUNT],
					// Feeding guide
					scoopsFood: parseInt(`${feedingGuide.scoop_amount}`),
					scoopsWater: parseInt(`${feedingGuide.scoop_amount}`),
					calories: feedingGuide.required_calories,
					adultScoops: adultFeedingGuide
						? parseInt(`${adultFeedingGuide?.scoop_amount}`)
						: undefined,
					// Subscription info
					trialSize: packWeights[2],
					trialLength: packWeights[3],
					subLength: packWeights[1],
					baseSize: adultPackWeights
? adultPackWeights[0]
: packWeights[0],
					basePrice,
					basePuppyPrice:
						basePrice !== planPrice
? subProduct.price / 100
: null,
					basePuppySize: adultPackWeights
? packWeights[0]
: null,
					// Recipes
					shuffledRecipes: shuffledRecipes,
					benefits: benefits,
					tags: tags,
					shuffledUnsuitableRecipes: shuffledUnsuitableRecipes,
					selectedRecipes: selectedRecipes,
					availableTreats: filteredTreats || [],
					// Plan
					price_test: priceTest,
					selectedPlan: "standard",
					planPrice,
					planLength: packWeights[1],
					planSize: packWeights[0],
					planScoops: parseInt(`${feedingGuide.scoop_amount}`),
				};

				return transformedPetDetails;
			})
		);
	}, [
		isFunnelDataComplete,
		data,
		getAllPackWeights,
		user,
		email,
		firstName,
		marketingOptIn,
		updateUser,
		getTagsAndBenefits,
		activePets,
	]);

	const storeEmail = useCallback(
		(
			pets: Pet[],
			newEmail?: string,
			newFirstName?: string,
			newAcceptsMarketing: boolean = false
		) => {
			identify({
				first_name: newFirstName || firstName,
				email: newEmail || email,
				phoneNumber: "",
				acceptsMarketing: (newAcceptsMarketing && 1) || marketingOptIn
? 1
: 0,
				pets: pets?.map((pet: Pet) => {
					return {
						name: pet.name,
						gender: pet.gender,
						breed: pet.breed,
						breed_count: pet.breedCount,
						age: pet.age,
						allergies: pet.allergies[0] === "none"
? []
: pet.allergies,
						ailments: pet.healthIssues[0] === "none"
? []
: pet.healthIssues,
						weight: pet.weight,
						activity: pet.activity,
						lifestage: pet.lifestage,
						scoops: pet.scoopsFood,
						fussy: pet.fussy,
						diet: pet.diet,
						treats_fed: pet.treatsFed,
						digestion: pet.digestion,
						behavioural: pet.behavioural,
						stools: pet.stools,
						improveHealth: pet.improveHealth,
						mobility: pet.mobility,
						oral: pet.oral,
						coat: pet.coat,
						current_discount_code: user.voucher?.future_discounts?.[0]?.code,
						current_discount_deliveries: parseInt(
							user.voucher?.future_discounts?.[0]?.set_limit_amount || "1"
						),
						current_discount_type: user.voucher?.future_discounts?.[0]?.type,
						current_discount_value: user.voucher?.future_discounts?.[0]?.amount,
						initial_discount_code: user.voucher?.code,
						initial_discount_deliveries: parseInt(
							user.voucher?.set_limit_amount || "1"
						),
						initial_discount_type: user.voucher?.type,
						initial_discount_value: user.voucher?.amount,
					};
				}),
			});
		},
		[
email,
firstName,
marketingOptIn,
user.voucher
]
	);

	return {
		isFunnelDataComplete,
		createPetFromStore,
		storeEmail,
	};
};
